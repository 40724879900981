<script>
import { makeProp } from "@/util/props";
import { isEmptyObject } from "@/util/inspect";
import { PROP_TYPE_OBJECT, PROP_TYPE_STRING } from "@/constants/props";
import { XFormInput } from "@/components/ui-components/form-input";
import { XModalCenter } from "@/components/ui-components/modal-center";
import { XFormSelect } from "@/components/ui-components/form-select";
import DatePicker from "vue2-datepicker";
import { v4ServiceApi } from "@/services/v4/v4.service";
import BaseFormTagInput from "@/components/Reusable/BaseFormTagInput";

export default {
  name: "SettingsTaskModal",
  components: {
    XFormInput,
    XModalCenter,
    XFormSelect,
    DatePicker,
    BaseFormTagInput,
  },
  props: {
    upsertType: makeProp(PROP_TYPE_STRING, "create", (type) =>
      ["create", "edit"].includes(type)
    ),
    editItem: makeProp(PROP_TYPE_OBJECT, {
      phones: [],
      template: null,
    }),
    templates: {
      type: Array,
    },
    list: makeProp(PROP_TYPE_OBJECT, {
      types: [],
      periods: [],
    }),
  },
  emits: ["data-updated", "close-modal"],
  data() {
    const form = {
      phones: [],
      template: null,
      sendAll: false,
    };
    return {
      applyButtonLoading: false,
      form,
      item: JSON.parse(JSON.stringify(form)),
    };
  },

  created() {
    if (this.upsertType === "edit") {
      this.setEditData();
    }
    setTimeout(() => this.$refs["creating-observer"].reset(), 10);
  },
  computed: {
    templateOptions() {
      return this.templates.map((el) => ({
        text: [
          this.list.types[el.type][this.$i18n.locale],
          this.list.periods[el.period][this.$i18n.locale] + "\n",
          el.time,
        ].join(", "),
        value: el.id,
      }));
    },
  },
  methods: {
    setTags(ts) {
      this.item.phones = ts;
    },
    setEditData() {
      if (isEmptyObject(this.editItem)) {
        return;
      }
      this.item.sendAll = this.editItem.phones.length === 0;
      this.item.phones = [...this.editItem.phones];
      this.item.template = this.editItem.notification_template.id;
    },
    closeModal() {
      console.log(1);
      this.clearForm();
      setTimeout(() => this.$emit("close-modal"), 0);
    },
    startLoading() {
      this.applyButtonLoading = true;
    },
    finishLoading() {
      this.applyButtonLoading = false;
    },

    async saveItem() {
      const isSatisfied = await this.$refs["creating-observer"].validate();
      if (isSatisfied) {
        this.startLoading();
        const d = {
          phones: this.item.sendAll ? [] : this.item.phones,
          notification_template_id: this.item.template,
        };
        if (this.upsertType === "edit") {
          d.id = this.editItem.id;
        }
        try {
          await v4ServiceApi.notificationTasks[
            this.upsertType === "edit" ? "update" : "create"
          ](d);

          this.clearForm();
          this.$emit("data-updated");
        } catch (e) {
          this.toastedWithErrorCode(e);
        } finally {
          this.finishLoading();
        }
      }
    },
    clearForm() {
      this.item = JSON.parse(JSON.stringify(this.form));
      this.$refs["creating-observer"].reset();
    },
  },
};
</script>

<template>
  <x-modal-center
    :bilingual="true"
    apply-button-text="save"
    cancel-button-text="cancel"
    footer-class="d-flex justify-content-between x-gap-1"
    apply-button-class="w-100"
    cancel-button-class="w-100"
    :apply-button-loading="applyButtonLoading"
    :modal-container-style="{
      'max-width': '600px',
      width: '75%',
      overflowY: 'scroll',
    }"
    @close="closeModal"
    @cancel="closeModal"
    @apply="saveItem"
  >
    <template #header>
      <h3 class="x-font-size-36px font-craftworksans color-gray-600">
        {{ upsertType === "edit" ? $t("edit") : $t("add") }}
      </h3>
    </template>

    <template #body>
      <validation-observer
        ref="creating-observer"
        class="client-type-creating-body"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="$t('template')"
          rules="required"
          class="title-uz-provider mb-4"
        >
          <x-form-select
            v-model="item.template"
            :bilingual="true"
            :placeholder="$t('type')"
            :options="templateOptions"
          />
          <span v-if="errors[0]" class="error__provider">
            {{ errors[0] }}
          </span>
        </validation-provider>
        <div class="mb-3">
          <b-form-checkbox v-model="item.sendAll" switch>
            {{ $t("sendEverybody") }}
          </b-form-checkbox>
        </div>
        <validation-provider
          v-if="!item.sendAll"
          name="phones"
          rules="requred"
          class="filter__inputs-input"
          style="margin-top: 0"
        >
          <base-form-tag-input
            ref="base-form-tag-input"
            :default-tags="item.phones"
            :placeholder="`${$t('phones')}`"
            @set-tags="setTags"
            class="base-input"
            style="margin-top: 0"
          >
            <template #delete-content>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10" cy="10" r="10" fill="#9CA3AF" />
                <path
                  d="M13.125 6.875L6.875 13.125"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.875 6.875L13.125 13.125"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
          </base-form-tag-input>
        </validation-provider>
      </validation-observer>
    </template>
  </x-modal-center>
</template>

<style lang="scss" scoped>
@import "./date-picker.css";

.client-type-creating-body {
  margin-top: 1rem;
  margin-bottom: 1em;
  display: grid;
  grid-template-columns: 1fr;
  font-family: Inter, sans-serif;
  color: var(--gray-600);
}

.icons-collection-wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.5rem;
}

.status-pick-color-title {
  font-size: 18px;
  color: var(--gray-400);
  font-weight: 500;
  font-family: Inter, serif;
}

.error__provider {
  color: red;
  font-size: 12px;
}

.filter__inputs {
  margin-top: 2rem;
  margin-bottom: 3rem;

  &-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 20rem;
    min-height: 4rem;
    background-color: var(--gray-100);
    border-radius: 2rem;
    padding: 1rem 1.25rem;
    margin-top: 1.5rem;
    width: 100%;
    border: none;
    color: var(--gray-600);
    position: relative;

    ::placeholder {
      color: var(--gray-600);
      opacity: 0.5;
    }

    .placeholder {
      color: var(--gray-600);
      //padding-left: 1rem;
    }

    .input__date {
      margin-left: 0.5rem;
      background-color: transparent;
      border: none;
    }

    .inline {
      background-color: transparent;
      border: none;
      color: var(--gray-600);
      padding: 0;

      .disabled__option {
        color: var(--gray-100) !important;
      }
    }
  }
}
</style>
