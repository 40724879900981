<script>
import SettingsPermission from "@/permission/settings.permission";
import { XButton } from "@/components/ui-components/button";
import { XIcon } from "@/components/ui-components/material-icons";
import BaseTabPicker from "@/components/Reusable/BaseTabPicker.vue";
import TemplateView from "./Template.vue";
import TaskView from "./Task.vue";
import { v4ServiceApi } from "@/services/v4/v4.service";

export default {
  name: "SettingsStatuses",
  components: {
    BaseTabPicker,
    XButton,
    TemplateView,
    TaskView,
    XIcon,
  },
  data() {
    return {
      tabs: ["template", "task"],
      current: this.$route.query.tab || "template",
      list: {
        types: [],
        periods: [],
        statuses: [],
      },
      permission: {
        view: SettingsPermission.getPermission("translations.view"),
        create: SettingsPermission.getPermission("translations.create"),
        edit: SettingsPermission.getPermission("translations.edit"),
        delete: SettingsPermission.getPermission("translations.delete"),
      },
    };
  },
  methods: {
    setTab(e) {
      this.current = e;
    },
  },
  watch: {
    current(v) {
      this.$router.replace({
        query: {
          tab: v,
        },
      });
    },
  },
  async created() {
    v4ServiceApi.notificationTemplates.typesPeriodsList().then(({ data }) => {
      this.list.types = data.type;
      this.list.periods = data.period;
      this.list.statuses = data.status;
    });
  },
};
</script>

<template>
  <div v-if="permission.view" class="app-settings-client-type">
    <div class="d-flex justify-content-between mb-4">
      <base-tab-picker
        :options="tabs"
        no-all
        :current="current"
        @tab-selected="setTab"
      />
      <div class="d-flex">
        <x-button
          v-if="permission.create"
          variant="secondary"
          text="add"
          :bilingual="true"
          @click="$refs.child.openCreateModal()"
        >
          <template #left-icon>
            <x-icon name="add" class="violet-600" />
          </template>
        </x-button>
      </div>
    </div>
    <template-view v-if="current === 'template'" ref="child" :list="list" />
    <task-view v-else ref="child" :list="list" />
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/utils/pagination";

@import "@/assets/scss/utils/b-table-redesign.scss";

.row-opacity {
  opacity: 0.2;
}
</style>
