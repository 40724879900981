<script>
import SettingsPermission from "@/permission/settings.permission";
import { XButton } from "@/components/ui-components/button";
import BaseLoading from "@/components/Reusable/BaseLoading.vue";
import { XIcon } from "@/components/ui-components/material-icons";
import { XCircularBackground } from "@/components/ui-components/circular-background";
import TaskModal from "./TaskModal.vue";
import { v4ServiceApi } from "@/services/v4/v4.service";
import DatePicker from "vue2-datepicker";
import { formatDateWithDot, phonePrettier } from "@/util/reusable";

export default {
  name: "SettingsNotificationsTemplate",
  components: {
    BaseLoading,
    XButton,
    XIcon,
    XCircularBackground,
    TaskModal,
    DatePicker,
  },
  props: ["list"],
  data() {
    return {
      templates: [],
      showModal: false,
      upsertType: "create",
      editStorage: {},
      table: {
        items: [],
        pagination: {
          current: 1,
          previous: 0,
          next: 0,
          perPage: 10,
          totalPage: 0,
          totalItem: 0,
        },
        loading: false,
      },
      permission: {
        view: SettingsPermission.getPermission("notifications.view"),
        create: SettingsPermission.getPermission("notifications.edit"),
        edit: SettingsPermission.getPermission("notifications.edit"),
        delete: SettingsPermission.getPermission("notifications.delete"),
      },
    };
  },
  computed: {
    tableFields() {
      const fields = [
        {
          key: "id",
          label: this.$t("ID"),
        },
        {
          key: "phones",
          label: this.$t("phones"),
        },
        {
          key: "type",
          label: this.$t("type"),
        },
        {
          key: "period",
          label: this.$t("period"),
        },
        {
          key: "ru",
          label: this.$t("message"),
        },
        {
          key: "uz",
          label: this.$t("message"),
        },
      ];
      if (this.permission.edit) {
        fields.push({
          key: "actions",
          label: this.$t("actions"),
          class: "float-right",
        });
      }
      return fields;
    },
  },
  created() {
    if (this.permission.view) {
      this.fetchItems();
    }
    v4ServiceApi.notificationTemplates
      .index({ is_all: true })
      .then(({ data }) => (this.templates = data.data));
  },
  methods: {
    dateReverser: (time) => formatDateWithDot(time),
    phonePrettier: (d) => phonePrettier(d),
    openCreateModal() {
      this.upsertType = "create";
      this.openModal();
    },
    openEditModal(item) {
      this.upsertType = "edit";
      this.editStorage = item;
      this.openModal();
    },
    startLoading() {
      this.table.loading = true;
    },
    finishLoading() {
      this.table.loading = false;
    },

    async fetchItems() {
      try {
        this.startLoading();
        const response = await v4ServiceApi.notificationTasks.index({
          page: this.$route.query.page || 1,
          limit: 20,
        });
        this.table.items = response.data.data;
        this.table.pagination = response.data.pagination;
      } catch (e) {
        this.toastedWithErrorCode(e);
      } finally {
        this.finishLoading();
      }
    },
    setUpsertType(eType) {
      if (["create", "edit"].includes(eType)) {
        this.upsertType = eType;
      }
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    dataUpdated() {
      this.closeModal();
      this.fetchItems();
    },
    async deleteItem(id) {
      this.$swal({
        title: this.$t("sweetAlert.title"),
        text: this.$t("sweetAlert.text"),
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("sweetAlert.yes"),
      }).then(async (result) => {
        if (result.value) {
          try {
            this.startLoading();
            await v4ServiceApi.notificationTasks.remove({ id });
            await this.fetchItems();
          } catch (e) {
            this.toastedWithErrorCode(e);
          } finally {
            this.finishLoading();
          }
        }
      });
    },
    async editItem(item) {
      try {
        this.editStorage = item;
        this.setUpsertType("edit");
        this.openModal();
      } catch (e) {
        this.toastedWithErrorCode(e);
      }
    },
  },
};
</script>

<template>
  <div v-if="permission.view" class="app-settings-client-type">
    <b-table
      id="my-table"
      ref="apartment-list-table"
      thead-tr-class="row__head__bottom-border"
      tbody-tr-class="row__body__bottom-border"
      class="table__list"
      sticky-header
      borderless
      show-empty
      responsive
      sort-icon-left
      :items="table.items"
      :fields="tableFields"
      :busy="table.loading"
      :empty-text="$t('no_data')"
    >
      <template #table-busy>
        <base-loading />
      </template>

      <template #empty="scope">
        <span class="d-flex justify-content-center align-items-center">
          {{ scope["emptyText"] }}
        </span>
      </template>
      <template #cell(is_active)="{ item }">
        <div v-if="item.is_active" class="d-flex x-gap-1 cursor-pointer">
          <x-circular-background class="bg-violet-600">
            <x-icon name="check" class="color-white" />
          </x-circular-background>
        </div>
      </template>
      <template #cell(phones)="{ item }">
        <div class="" v-if="item.phones.length">
          <div v-for="el in item.phones" :key="el">{{ phonePrettier(el) }}</div>
        </div>
        <div class="" v-else>
          {{ $t("sendEverybody") }}
        </div>
      </template>
      <template #cell(type)="{ item }">
        <div v-if="item.notification_template">
          {{ item.notification_template.type }}
        </div>
      </template>
      <template #cell(period)="{ item }">
        <div v-if="item.notification_template">
          {{ item.notification_template.period }}
        </div>
      </template>
      <template #cell(uz)="{ item }">
        <div v-if="item.notification_template">
          {{ item.notification_template.message.uz }}
        </div>
      </template>
      <template #cell(ru)="{ item }">
        <div v-if="item.notification_template">
          {{ item.notification_template.message.ru }}
        </div>
      </template>
      <template #cell(notification_template)="{ item }">
        <div class="">
          <div v-if="item.notification_template">
            {{ dateReverser(item.notification_template.time) }},
            {{ item.notification_template.time.split(" ")[1] }}
          </div>
          <div>{{ item.notification_template.message }},</div>
        </div>
      </template>

      <template #cell(actions)="{ item }">
        <div class="float-right d-flex x-gap-1 cursor-pointer">
          <x-circular-background
            v-if="permission.edit"
            class="bg-violet-600"
            @click="openEditModal(item)"
          >
            <x-icon name="edit" class="color-white" />
          </x-circular-background>

          <x-circular-background
            v-if="permission.delete"
            class="bg-red-600"
            @click="deleteItem(item.id)"
          >
            <x-icon name="delete" class="color-white" />
          </x-circular-background>
        </div>
      </template>
    </b-table>

    <task-modal
      v-if="showModal"
      :upsert-type="upsertType"
      :templates="templates"
      :edit-item="editStorage"
      @close-modal="closeModal"
      @data-updated="dataUpdated"
      :list="list"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/utils/b-table-redesign.scss";
</style>
